/**
 * The AnVIL
 * https://www.anvilproject.org
 *
 * Mapping from facet term group name to their corresponding display text.
 */

export const FacetGroupTermNameDisplay = {
  dataUseLimitation: "General",
  diseaseSpecificDataUseLimitation: "Disease Specific",
};
