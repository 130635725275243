/**
 * The AnVIL
 * https://www.anvilproject.org
 *
 * Mapping from facet name to their corresponding selection control e.g. "CHECKBOX", "TOGGLE".
 */

const FacetSelectionControl = {
  dataUseLimitationModifiers: "TOGGLE",
};

export default FacetSelectionControl;
