/**
 * The AnVIL
 * https://www.anvilproject.org
 *
 * Mapping search term logical operator.
 */

const DashboardSearchTermLogicalOperator = {
  AND: "AND",
  NAND: "NAND",
  OR: "OR",
};

export default DashboardSearchTermLogicalOperator;
