/**
 * The AnVIL
 * https://www.anvilproject.org
 *
 * Mapping from facet name to their corresponding facet selector display text.
 */

export const FacetSelectorNameDisplay = {
  accessType: "Access Type",
  consentCodes: "Consent Code",
  consentShortName: "Consent Code",
  consortium: "Consortium",
  dataTypes: "Data Type",
  dataUseLimitationModifiers: "Data Use Limitation Modifier",
  dataUseLimitations: "Data Use Limitation",
  diseases: "Disease",
  focuses: "Focus / Disease",
  platforms: "Platform",
  search: "Text Search",
  studyDesigns: "Study Design",
};
